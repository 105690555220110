<template>
  <div id="user-login">
    <img src="/home/logo-white.png" width="200">
    <h1>Logowanie</h1>
    <form @submit.prevent="login">
      <input type="email" v-model="email" placeholder="E-mail"/>
      <input type="number" v-model="password" placeholder="Pin"/>
      <button type="submit">Zaloguj</button>
    </form>
  </div>
</template>

<script>
import firebase from '@/firebase';

export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  mounted() {
    firebase.auth().onAuthStateChanged(user =>  {
      if (user) {
        this.$router.push('/games');
        this.$store.commit('SET_USER', user);
      }
    });
  },
  methods: {
    login() {
      firebase.auth().signInWithEmailAndPassword(this.email, this.password).then((user) => {
        this.$router.push('/games');
        this.$store.commit('SET_USER', user);
      }).catch(error => {
        alert(error.message);
      });
    }
  }
};
</script>

<style scoped>
#user-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(45deg, #0080bb, #00c1cf);
}
h1 {
  margin-top: 2rem;
  color: #fff;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
}
form {
  display: flex;
  flex-direction: column;
  width: 70%;
  max-width: 400px;
}
form * {
  margin: .5rem 0;
}
input {
  padding: .5rem;
  border-radius: .25rem;
  border: none;
  box-shadow: 0 0 20px 0 rgb(0, 0, 0, 0.5);
  font-family: 'Oswald', sans-serif;
}
button {
  border-radius: .25rem;
  background: #00aeff;
  box-shadow: 0 0 20px 0 rgb(0, 0, 0, 0.25);
}
</style>